import React from "react";
import Above from "../../components/global/GenericBanner";
import ContactUs from "../../components/global/forms/ContactUs";

const Contact = props => {
  const { entry } = props;
  const data = entry?.getIn(["data"])?.toJS();
  if (data) {
    return (
      <>
        <Above {...data} />
        <ContactUs {...data} />
      </>
    );
  }

  return <div>Loading...</div>;
};

export default Contact;
